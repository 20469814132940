#main {
  margin: auto;
  max-width: 600px;
  text-align: center;
  text-transform: uppercase;
}

#header {
  background-color: white;
  margin-top: 25px;
  padding: 15px;
  position: relative;
  z-index: 10;
}

#stats {
  left: 0;
  position: absolute;
  padding: 3px 6px;
  top: 0;
}

#social {
  font-size: 1.5em;
  opacity: 0.5;
  padding: 2px 5px;
  position: absolute;
  right: 0;
  top: 0;
}

#social a {
  color: black;
}

h1 {
  margin-top: 0;
  font-family: "Berkshire Swash", cursive;
  font-size: 3em;
  text-transform: none;
}

h1 .version {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.25em;
}

/* PAGES */

.page {
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 718px;
  transition: left 250ms, transform 250ms;
}

.choose-page:not(.selected) {
  left: 0;
  transform: translate(-100%);
}

.browse-page:not(.selected) {
  left: 100%;
  transform: translate(100%);
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
  }

  #main {
    max-width: 718px;
  }

  #header {
    margin-top: 35px;
  }
}
