@import url(https://fonts.googleapis.com/css?family=Berkshire+Swash);
@import url(https://fonts.googleapis.com/css?family=Roboto);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
